import { render, staticRenderFns } from "./device-status.vue?vue&type=template&id=16810676"
import script from "./device-status.vue?vue&type=script&lang=ts"
export * from "./device-status.vue?vue&type=script&lang=ts"
import style0 from "./device-status.vue?vue&type=style&index=0&id=16810676&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\nimbusworkspace\\nimbus-web-2\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16810676')) {
      api.createRecord('16810676', component.options)
    } else {
      api.reload('16810676', component.options)
    }
    module.hot.accept("./device-status.vue?vue&type=template&id=16810676", function () {
      api.rerender('16810676', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "nimbus-web-common/src/pages3/dashboard/device/device-status.vue"
export default component.exports